<template>
  <div style="position: relative; z-index: 0;">
    <v-card flat>
      <v-form ref="searchFilter">
        <v-row>
          <v-col cols="12">
            <v-select
              :rules="required"
              dense
              outlined
              label="Filter By Location"
              :items="locations"
              item-value="id"
              item-text="locationName"
              v-model="selectedLocation"
              return-object
            >
              <template v-slot:selection="{ item }">
                {{ item.locationName }}
                <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                <!-- render selected items -->
              </template>
              <template  v-slot:item="{ item }">
                {{ item.locationName }}
                <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName"  v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                <!-- render items when the select is open -->
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="12">
            <v-menu
              ref="startMenu"
              v-model="startMenu"
              :close-on-content-click="false"
              :return-value.sync="startDateTime"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :error="dateError"
                  :rules="required"
                  v-model="startDateTime"
                  label="Filter Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDateTime" no-title scrollable @change="$refs.startMenu.save(startDateTime); startMenu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          </v-row>
        <v-row>
          <v-col cols="4"  md="12">
            <v-menu
              ref="endMenu"
              v-model="endMenu"
              :close-on-content-click="false"
              :return-value.sync="endDateTime"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :error="dateError"
                  :rules="required"
                  v-model="endDateTime"
                  label="Filter End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDateTime" no-title scrollable @change="$refs.endMenu.save(endDateTime); endMenu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-btn dense @click="getBlocks" color="primary">Search</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="dateError">
          <v-col>
            <span class="error-text">Ensure dates are selected and that your selection is no greater than 5 days apart</span>
          </v-col>
        </v-row>
      </v-form>
    <v-card-title>
     Appointment Blocks    
    </v-card-title>
    <v-card-subtitle>Choose select mode: Single or Multi</v-card-subtitle>
    <v-data-table
      dense
      :options.sync="options"
      :headers="headers"
      :items="blocks.items"
      :loading="loadingAppointmentBlocks"
      :server-items-length="blocks.totalCount"
      :footer-props="{'items-per-page-options':[10, 20, 30,50]}"
      disable-sort
      :single-select="singleSelect === 0 ? true : false"
      show-select
      v-model="selected"
    >
    <template v-slot:top>
      <v-row justify="center">
        <v-col cols="12" md="6" class="py-0">
          <v-row
            align="center"
            justify="center"
          >
            <v-btn-toggle
              v-model="singleSelect"
              @change="toggleSelect"
              mandatory
              :disabled="blocks.items.length < 1"
              color="primary"
            >
              <v-btn>
                Single Select
              </v-btn>
              <v-btn>
                Multi Select
              </v-btn>
            </v-btn-toggle>
          </v-row>
       </v-col>
        <v-col cols="12" xs="12" md="5" lg="6"  class="d-flex justify-center align-center py-0">
          <v-btn color="error" large :disabled="singleSelect === 0 || selected.length < 1" @click="reviewDelete">Delete Multi-Blocks</v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.datetime_start`]="{ item }">
      <span>{{ formatDate(item.datetime_start) }}</span>
    </template>
    <template v-slot:[`item.datetime_end`]="{ item }">
      <span>{{ formatDate(item.datetime_end) }}</span>
    </template>
     <template v-slot:[`item.vaccineManufacturerName`]="{ }">
      <manufacturer-chip :manufacturerId="selectedLocation.vaccineManufacturerId" :manufacturerName="selectedLocation.vaccineManufacturerName"  v-if="selectedLocation.vaccineManufacturerId > 0"></manufacturer-chip>
    </template>
    <template v-slot:[`item.vaccineDoseType`]="{ }">
       <dose-type-chip :doseType="selectedLocation.vaccineDoseType" v-if="selectedLocation.vaccineDoseType"></dose-type-chip>
    </template>
    </v-data-table>
  </v-card>

  <!-- multi delete dialog -->
    <v-dialog v-model="showMultiDelete" max-width="800px">
      <multi-delete :key="renderkeyMulti" :blockList="selected" @closeMultiDelete="closeMultiDeleteDialog"></multi-delete>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['page'],
  computed: {
    ...mapGetters('vaccineLocations', ['locations', 'loadingAppointmentBlocks']),
  },
  components: {
    multiDelete: () => import("./blockMultiDeleteReleaseList"),
    manufacturerChip: () => import('../../../../vaccineManufacturer/components/manufacturerChip'),
    doseTypeChip: () => import('../../../../vaccineManufacturer/components/doseTypeChip')
  },
  watch: {
    selected: function (newValue, oldValue) {
      if(this.singleSelect === 0) {
        this.$emit('updateBlockForm', newValue)
      } else {
        this.$emit('updateBlockForm', {})
      }
    },
    options: {
      handler () {
        this.getBlocks()
      },
      deep: true
    }
  },
  data () {
    return {
      renderkeyMulti: 0,
      showMultiDelete: false,
      singleSelect: 0,
      options: { },
      endMenu: false,
      startMenu: false,
      required: [
        v => !!v || 'Required Field'
      ],
      startDateTime: null,
      dateError: false,
      endDateTime: null,
      selectedLocation: null,
      blocks: {
        items: []
       },
      selected: [],
      headers: [
        { text: 'Location', value: 'locationName', sortable: false },
        { text: 'Start', value: 'datetime_start', sortable: false },
        { text: 'End', value: 'datetime_end', sortable: false },
        { text: 'Capacity', value: 'capacity', sortable: false },
        { text: 'Manufacturer', value: 'vaccineManufacturerName', sortable: false},
        { text: 'Dose Type', value: 'vaccineDoseType', sortable: false}
      ]
    }
  },
  methods: {
    ...mapActions('vaccineLocations', ['getAvailabilityBlocks']),
    toggleSelect(e) {
      this.selected = [];
      this.$emit('updateMultiSelect', this.singleSelect);
    },
    closeMultiDeleteDialog(e) {
      this.showMultiDelete = false;
      this.selected = [];
      this.getBlocks();
    },
    reviewDelete(){
      this.renderkeyMulti++;
      if(this.selected.length > 0) {
        this.showMultiDelete = true;
      }
    },
    prevPage: function () {
      if (this.page > 0) {
        this.$emit('updatePage', this.page - 1)
      }
    },
    nextPage: function () {
      this.$emit('updatePage', this.page + 1)
    },
    formatDate: function (date) {
      let utcDate = moment.utc(date).toDate()
      return moment(utcDate).local().format('MMM Do h:mm a')
    },
    getBlocks: function () {
      if (!this.$refs.searchFilter.validate()) {
        return false
      }

      if (this.startDateTime === '' || this.endDateTime === '' || moment(this.endDateTime).diff(this.startDateTime, 'days') < 0 || moment(this.endDateTime).diff(this.startDateTime, 'days') > 4) {
        this.dateError = true
        return false
      } else {
        this.dateError = false
      }

      const { page, itemsPerPage } = this.options

      let startDateTime = moment(this.startDateTime).startOf('day').toISOString(true)
      let endDateTime = moment(this.endDateTime).endOf('day').toISOString(true)

      this.getAvailabilityBlocks({ page: page - 1, pageSize: itemsPerPage, locationId: this.selectedLocation.id, startDateTime: startDateTime, endDateTime: endDateTime }).then((response) => {
          this.blocks = response.data
          if(this.blocks.items === null || this.blocks.items === undefined) {
            this.blocks.items = [];
          }
      })
    }
  }
}
</script>

<style>
.styleDateTime {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    min-height: 40px;
    padding: 5px;
    width: 220px;
}

.styleDateTimeError {
  border: 2px solid #ce2130 !important;
}

.error-text {
  color: #ce2130;
}

.styleDateTimeError {
  border: 2px solid #ce2130 !important;
}

.error-text {
  color: #ce2130;
}

.metric-datetime {
  display: flex;
}

.datetime-label {
  margin-top: 10px;
  margin-right: 10px;
  font-size: 14pt;
}

.dateTimelabel {
  padding-top: 8px;
  padding-right: 5px;
  width: 50px;
}

</style>
